import {createAction, props} from '@ngrx/store';
import {ClientDto} from "../models/client.model";

export const loadClients = createAction('[User] Load Clients',props<{filter:any}>());
export const loadClientsSuccess = createAction('[User] Load Clients Success', props<{ clients: ClientDto[] }>());
export const loadClientsFailure = createAction('[User] Load Clients Failure', props<{ error: any }>());

export const addClient = createAction('[Client] Add Client', props<{ client: ClientDto }>());
export const addClientSuccess = createAction('[Client] Add Client Success', props<{ client: ClientDto }>());
export const addClientFailure = createAction('[Client] Add Client Failure', props<{ error: any }>());

export const updateClient = createAction('[Client] Update Client', props<{ id: number, client: ClientDto }>());
export const updateClientSuccess = createAction('[Client] Update Client Success', props<{ client: ClientDto }>());
export const updateClientFailure = createAction('[Client] Update Client Failure', props<{ error: any }>());

export const deleteClient = createAction('[Client] Delete Client', props<{ id: number }>());
export const deleteClientSuccess = createAction('[Client] Delete Client Success', props<{ id: number }>());
export const deleteClientFailure = createAction('[Client] Delete Client Failure', props<{ error: any }>());
